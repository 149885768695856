import React from "react";

const BlogCard = ({
  tags,
  title,
  coverPhoto,
  datePublished,
  slug,
  content,
}) => {
  const firstSentence = content.html.match(/<p>([^.!?]*[.!?]).*?<\/p>/i)[1];

  return (
    <div className="blog-card">
      <div className="blog-card-thumbnail">
        <img src={coverPhoto.url} alt={coverPhoto.url} />
      </div>
      <div className="blog-card-body">
        <div className="blog-card-title">
          <h1>{title}</h1>
          <h3>{datePublished}</h3>
        </div>
        <div className="blog-card-tags tags">
          {tags.map((tag) => (
            <h3>{tag} </h3>
          ))}
        </div>
        <div className="blog-card-content">
          <div
            className="blog-card-desc"
            dangerouslySetInnerHTML={{ __html: firstSentence }}
          />
          <a className="blog-card-slug" href={content.slug}>
            Read more
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
