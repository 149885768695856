import React from "react";
import avatar from "./avatar.gif";
import profilepic from "./profilepicsq1.png";
import sakura from "./sakura.png";
import border from "./border.png";
import "./NewAbout.css";
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import NewHeader from "../../../Header/NewHeader";

const NewAbout = () => {
    return (
        <div className="about">
        <NewHeader/>
        <div className="about-container">
            <div className="about-card">
                <div className="about-title title">
                    <h1>
                        PROFILE
                        <img
                            src={avatar}
                            id="avatar"
                            width="2px"
                            height="2px"
                            alt="pixel-avatar"
                        />
                    </h1>
                </div>
                <div className="about-body">
                <div className="about-left">
                    <div className="about-profile-photo">
                        <img src={border} id="profile-border" width="30vh" alt="border" />
                        <img src={profilepic} id="profile-pic" alt="" />
                    </div>
                    <div className="about-left-body">
                        <h3>KOH SHERLYNN PHYLLISA </h3>
                        <img src={sakura} id="sakura-bot" alt="sakura border bot"></img>
                        <img src={sakura} id="sakura-top" alt="sakura border top"></img>
                        <div className="about-left-socials">
                            <a href="https://github.com/sherphy" target="_blank" id="github">
                                <AiFillGithub size="60%" color="#303231" />
                                {window.innerWidth > 480 && <h6> GitHub </h6>}
                            </a>
                            <a
                                href="https://www.linkedin.com/in/sherlynnphyllisa/"
                                target="_blank"
                                id="linkedin"
                            >
                                <AiFillLinkedin size="60%" color="#303231"></AiFillLinkedin>
                                {window.innerWidth > 480 && <h6> LinkedIn</h6>}
                            </a>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 480 && (
                    <div className="about-right">
                        <div className="about-background">
                            <h1>BACKGROUND</h1>
                            <h5> <span>♡</span> I graduated from National University of Singapore with a Double Major in
                                Bachelor of Engineering (Honours) </h5>
                                <h5> <span>♡</span> I took way more modules than required because I was interested in everything... therefore my
                                areas of formal education include: Software Engineering, Civil Engineering, 
                                Finance, Liberal Arts
                            </h5>
                        </div>
                        <div className="about-interests">
                        <h1>INTERESTS</h1>
                            <h5> <span>♡</span> <a href="https://www.goodreads.com/review/list/90326178?ref=nav_mybooks" target="_blank">Literature</a> and philosophy, especially gender studies - my favourite writer is Helene Cixous.
                            Send recommendations!!! ^O^ </h5>
                            <h5> <span>♡</span> I love collecting cute things like Sanrio, Popmart, game/anime merch, and crystals </h5>
                        </div>
                        <div className="about-skills">
                        <h1>SKILLS</h1>
                        <h5> <span>♡</span> Software Engineering: Fullstack Web Dev, IoT, DSA </h5>
                        <h5> <span>♡</span> Civil Engineering: Hate structural, love transport and PM, ok with everything else</h5>
                        <h5> <span>♡</span> Finance: Patent analysis, seed funding, econs/accounting</h5>
                        <h5> <span>♡</span> Will meow if necessary</h5>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
        </div>
    );
};

export default NewAbout;
