import React from "react";
import "./NewHeader.css";

const NewHeader = () => {
  return (
    <div className="header">
      <div className="header-box">
        <div className="header-nav">
          <nav>
            <a href="/">A̲bout</a>
            <a href="/projects">P̲rojects</a>
            {/* <a href="/blog">B̲log</a> */}
            {/* <a href="/contact">C̲ontact</a> */}
            <a href="/upcoming">Upcoming</a>
          </nav>
        </div>
        <div className="header-content">
          {window.innerWidth > 480 ?
          <>
          <h5 className="font-face-bcr"> Hello, my name is </h5>
          <h5 className="font-face-bcr mb-2"> <span className="font-face-bcrblink"> &#x3e; </span> Koh Sherlynn Phyllisa</h5>
            {window.innerWidth > 1300 ? 
            <h5 className="font-face-bcr"> Welcome to my site ^.^ </h5>
:<> <h5>Your window width is: {window.innerWidth}px</h5>
<h5>My site is best viewed above 1300px</h5> </>
        }
            </> : <h5 className="font-face-bcr"> plz use desktop lol </h5>
          }
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
