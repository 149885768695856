import React from 'react'
import Projects from './Projects/Projects'
import NewAbout from './About/NewAbout'
import './Home.css'


const Home = () => {

  return (
    <div className='home-container'>
        <NewAbout className='about-page-container'/>
        <Projects className='projects-page-container'/>
    </div>
  )
}

export default Home