import React from 'react'
import './StickyHeader.css';

const StickyHeader = () => {
    return (
        <div className="sticky-header">
            <nav className="sticky-header-nav">
                <a href="/">A̲bout</a>
                <a href="/projects">P̲rojects</a>
                {/* <a href="/blog">B̲log</a> */}
                {/* <a href="/contact">C̲ontact</a> */}
                <a href="/upcoming">Upcoming</a>
            </nav>
        </div>
    )
}

export default StickyHeader