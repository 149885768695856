import React from 'react'
import StickyHeader from '../../../Header/StickyHeader'
import './Projects.css'
import projectData from './ProjectData.js'
import gd from './gd.png';

const Projects = () => {
  return (
    <div className='projects-container'>
      <StickyHeader/>
      <div className='project-title title'> 
      <img src="https://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/ef0fcf950a20f32.png"/>
      <div className="project-title-banner">
      <h1> WebDev Projects</h1>
      </div>
      </div>
      <div className="projects card">
        {projectData.map((data, key) => (
          <div id={`project-${key}`} key={key}>
            <div className="project-thumbnail-container">
            <img className="project-thumbnail" src={data.thumbnail} alt={`${data.title}`}/>
            </div>
            <div className="project-body">
            <h1 className='project-title'>{data.title}</h1>
            <div className='project-tags tags'>{data.tags
            .sort()
            .map((tag) =>
            <h3>{tag}</h3>
            )}
            </div>
            <h5 className="project-purpose">Purpose: {data.purpose}</h5>
            <h5 className='project-desc'>{data.desc}</h5>
            {/* <h5 className='project-source'><a href={data.source}>Source</a></h5> */}
              </div>
              <div className="project-footer">
                { data.link && 
                <a className="project-demo" href={data.link} target="_blank">Live demo</a> }
              </div>
          </div>
        ))}
      </div>
      {/* <div className="projects-extra">
        <h5>Open source contributions:</h5>
        <h5>React - README for DevTools not appearing due to Firefox CSP</h5>
      </div> */}
      <img src={gd} id="graphic-design"/>
    </div>
  )
}

export default Projects