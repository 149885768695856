import cosmeticsThumbnail from './Thumbnails/cosmetics.gif';
import popmart from './Thumbnails/popmart.gif';
import taylor from './Thumbnails/taylor.gif';
import tft from './Thumbnails/tft.gif';

export default [
    {
        "thumbnail": cosmeticsThumbnail,
        "title":"Event NX Cosmetic Planner",
        "purpose": "Consolidate item selection across characters, and approximate points necessary",
        "desc":"Event items are chosen by their ID, and called using MapleStory API. Users can then select items for different characters, which will then be stored in respective character inventories on localStorage",
        "tags": ["API", "React", "localStorage"],
        "source": "https://github.com/sherphy/planner-calculator",
        "link": ""
    },
    {
        "thumbnail": popmart,
        "title": "Popmart Blindbox Pull Simulator",
        "purpose": "Gachapon to simulate opening POPMART blindboxes of different series",
        "desc": "I populated a JSON of over 100 POPMART characters on MongoDB, with fields of their respective mascots, series, names, and rarity. Backend is hosted on Render free tier, therefore there might be a 30 second delay in retrieval upon initial load",
        "tags": ["MERN", "Vercel", "Render", "API"],
        "link": "https://popmart-sim.vercel.app/"
    },
    {
        "thumbnail": tft,
        "title": "Teamfight Tactics Champion Tier list",
        "purpose": "Rendering and filtering TFT champions for quick and accessible meta tier list reference",
        "desc": "Selecting game stage or typing champion name results in different intuitive render formats, in order for users to make the best decisions in the shortest time. Champion information from Riot Developer Portal",
        "tags": ["Typescript", "React", "Netlify", "API", "Jest"],
        "link": "https://tft-champ-tier.netlify.app/"
    },
    {
        "thumbnail": taylor,
        "title": "Taylor Swift Song Ranker (WIP)",
        "purpose": "Accurately determine personal song ranking in least amount of clicks",
        "desc": "By user vote, separates songs into winner and loser brackets, then merge sort individual brackets, before insertion sort loser bracket into winner bracket. To recall song, click on album to preview song",
        "tags": ["React", "DSA", "API", "Tailwind"],
        "link": "https://taylor-song-ranker.netlify.app/"
    },
]