import "./blog.css";
import React, {useState, useEffect} from 'react'
import { GraphQLClient, gql } from "graphql-request";
import BlogCard from "./BlogCard";
import StickyHeader from "../../Header/StickyHeader";

const graphcms = new GraphQLClient ("https://ap-southeast-2.cdn.hygraph.com/content/clbjmz8n32eh001t6a7a02075/master");

const QUERY = gql`{
    posts {
        datePublished
        id
        slug
        title
        tags
        coverPhoto {
            url
        }
        content {
            html
        }
    }
}`;

const Blog = () => {
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const {posts} = await graphcms.request(QUERY);
                setPosts(posts);
            }
            catch (error) {
                console.error(error); 
            }
        };
        fetchData();
    }, []);
    

  return (
    <div className="blog-container">
        <StickyHeader/>
        <div className="blogs">
        {posts.map((post) => (
            <BlogCard title={post.title} coverPhoto={post.coverPhoto} key={post.id} 
            datePublished={post.datePublished} slug={post.slug} content={post.content} tags={post.tags}/>
        ))}
        </div>
    </div>
  )
}

export default Blog