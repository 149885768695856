import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Blog from "./components/pages/Blog/Blog"
import Home from './components/pages/Home/Home.jsx';
import Projects from './components/pages/Home/Projects/Projects';
import Upcoming from './components/pages/Upcoming/Upcoming';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/upcoming" element={<Upcoming/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
