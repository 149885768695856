import React from 'react'

const Upcoming = () => {
  return (
    <div className='upcoming-container'>
        <h1>Slowly editing this website! These are the things that will be added</h1>
            <ul>
                <li>Classification of my different skills</li>
                <li>Links to my previous works in blog format</li>
            </ul>
            <h2>Current projects I'm working on</h2>
            <h5>Categorised event calendar for things I'm interested in using NextJS </h5>
            <h5>Facebook-esque website except it's just a repo of my stuff with NextJS </h5>
            <h5>Bot to automate booking my spin classes using puppeteer</h5>
    </div>
  )
}

export default Upcoming